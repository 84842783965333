import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Container, Row } from 'reactstrap';
import ReactTableBase from 'src/components/tables/newtable/table/ReactTableBase';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds, selectUserPermissions, selectUserToken } from 'src/redux/user/user.selectors';
import clsx from 'clsx';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Divider from '@material-ui/core/Divider';
import { GetUsers, CreateUser, UpdateUser, DeleteUser, SearchUser, CreateAdPreRegisteredUser } from 'src/services/user-http.service';
import { useToolbarStyles } from 'src/assets/jss/components/tableToolbarStyle';
import { useTableStyles } from 'src/assets/jss/components/tableStyle';
import FormTextField from 'src/components/form-elements/form-text-field.component';
import FormEmailField from 'src/components/form-elements/form-email-field.component';
import OrganizationSelect from 'src/components/form-elements/organization-select.component';
import RoleSelect from 'src/components/form-elements/role-select.component';
import MarketMultiselect from 'src/components/form-elements/extendedSearch-market-multiselect.component';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import DoneButton from 'src/components/buttons/done-button.component';
import Toolbar from '@material-ui/core/Toolbar';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { permission } from 'src/auth/permissions';
import VehiclePageDialog from 'src/components/form-elements/vehiclePage-dialog.component';
import Fade from 'react-reveal/Fade';
import SearchField from 'src/components/buttons/search-icon-button-new.component';
import magnifyicon_hover from 'src/assets/img/vehicles/magnifyicon_hover.png';
import magnifyIcon from 'src/assets/img/vehicles/magnifyIcon.png';
import Icon_Edit_hover from 'src/assets/img/icons/Icon_Edit_hover.png';
import Icon_Edit from 'src/assets/img/icons/Icon_Edit.png';
import Icon_Add from 'src/assets/img/icons/Icon_Add.png';
import Icon_Add_hover from 'src/assets/img/icons/Icon_Add_hover.png';
import Icon_Delete from 'src/assets/img/icons/Icon_Delete.png';
import Icon_Delete_hover from 'src/assets/img/icons/Icon_Delete_hover.png';

let rowsPerPage = 500;

let newPageVariable = 1;

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, handleClickOpen, handleClickDelete, handleClickEdit, enableSelect } = props;
    const [hovered, setHovered] = useState(false);
    const [hoveredPlus, setHoveredPlus] = useState(false);
    const [hoveredDelete, setHoveredDelete] = useState(false);

    return (
        <Toolbar
            className={clsx(classes.root1, {
                [classes.highlight1]: numSelected > 0,
            })}
        >
            <div className={classes.tooltipDiv}>
                <Tooltip
                    className={classes.tooltipImg}
                    title="Add"
                    onClick={handleClickOpen}
                    onOpen={() => setHoveredPlus(true)}
                    onClose={() => setHoveredPlus(false)}
                >
                    <IconButton aria-label="filter list">
                        <img
                            className="rvIcons"
                            src={hoveredPlus ? Icon_Add_hover : Icon_Add}
                            alt="RV Image"
                        />
                    </IconButton>
                </Tooltip>
                <Tooltip
                    className={classes.tooltipImg1}
                    title="Edit"
                    onClick={handleClickEdit}
                    onOpen={() => setHovered(true)}
                    onClose={() => setHovered(false)}
                >
                    <IconButton disabled={enableSelect} aria-label="edit">
                        <img
                            className="rvIcons"
                            src={hovered ? Icon_Edit_hover : Icon_Edit}
                            alt="RV Image"
                        />
                    </IconButton>
                </Tooltip>
                <Tooltip
                    className={classes.tooltipImg1}
                    title="Delete"
                    onClick={handleClickDelete}
                    onOpen={() => setHoveredDelete(true)}
                    onClose={() => setHoveredDelete(false)}
                >
                    <IconButton disabled={enableSelect} aria-label="delete">
                        <img
                            className="rvIcons"
                            src={hoveredDelete ? Icon_Delete_hover : Icon_Delete}
                            alt="RV Image"
                        />
                    </IconButton>
                </Tooltip>
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.string.isRequired,
};

const UserTable = ({ userOrg, userPermissions }) => {
    const [isEditable, setIsEditable] = useState(false);
    const [isResizable, setIsResizable] = useState(false);
    const [withDragAndDrop, setWithDragAndDrop] = useState(false);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [selected, setSelected] = useState('');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const classes = useTableStyles();
    const [sliderUser, setSliderUser] = useState({
        firstName: "",
        lastName: "",
        email: "",
        role: "",
        roleId: "",
        organization: "",
        organizationId: "",
        marketIds: [],
        editMode: false
    });

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [searchInput, setSearchInput] = useState('');

    const [showText, setShowText] = useState(false);
    const [showTable, setShowTable] = useState(false);

    const imgsMagnifyIcon = [magnifyIcon, magnifyicon_hover];

    const [iconMagnify, setIconMagnify] = useState(imgsMagnifyIcon[0]);
    const [openIconMagnify, setOpenIconMagnify] = useState(false);
    const [showSearchBar, setShowSearchBar] = useState(false);

    const [displayValue, setDisplayValue] = useState('');
    const [displayUsers, setDisplayUsers] = useState(false);
    const [displayRoles, setDisplayRoles] = useState(false);
    const [displayPermissions, setDisplayPermissions] = useState(false);
    const [displayOrganizations, setDisplayOrganizations] = useState(false);
    const [displayFleets, setDisplayFleets] = useState(false);
    const [displayInternalAssets, setDisplayInternalAssets] = useState(false);
    const [enableSelect, setEnableSelect] = useState(true);

    const [toggleSearchBar, setToggleSearchBar] = useState(false);

    const columns = useMemo(
        () => [
            { Header: 'First Name', accessor: "firstName", disableGlobalFilter: true, },
            { Header: 'Last Name', accessor: "lastName", disableGlobalFilter: true, },
            { Header: 'Email', accessor: "email", disableGlobalFilter: true, },
            { Header: 'Role', accessor: "role", disableGlobalFilter: true, },
            { Header: 'Organization', accessor: "organization", disableGlobalFilter: true },
        ],
        [],
    );

    const [selectedRows, setSelectedRows] = useState({ "0": true, "9": true });
    const [rows, setUsers] = useState([]);

    useEffect(() => {
        initializeUsers();
    }, []);

    useEffect(() => {
        if (searchInput.length === 0) {
            initializeUsers();
        }
    }, [searchInput]);

    useEffect(() => {
        setDisplayUsers(true);
        setDisplayValue('Users')
        const timer1 = setTimeout(() => {
            setShowText(true);
        }, 300); // Adjust the delay as needed

        const timer2 = setTimeout(() => {
            setShowTable(true);
        }, 1000); // Adjust the delay as needed
        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
        };
    }, []);

    const getUsers = (pageNumber, pageSize) => {
        let userArray = [];

        GetUsers({ organizationId: userOrg.id, pageNumber: pageNumber, pageSize: pageSize })
            .then(data => {
                if (data.ok === true) {
                    if (data && Array.isArray(data.users)) {
                        setTotalPages(data.totalPages)
                        data.users.map(user => {
                            userArray.push(
                                {
                                    firstName: user.firstName,
                                    lastName: user.lastName,
                                    email: user.email,
                                    role: user.role.name,
                                    organization: user.organization.name,
                                    marketIds: user.marketIds,
                                    roleId: user.role.id,
                                    organizationId: user.organization.id,
                                }
                            );
                        });
                    }
                    setUsers(userArray);
                } else {
                    setOpenDialog(true);
                    setDialogMessage(data.errorMessage);
                }
            });
    };

    const initializeUsers = () => {
        getUsers(newPageVariable, rowsPerPage);
    };

    const handleSliderOpen = () => {
        setOpen(true);
        setSliderUser({
            ...sliderUser, firstName: "", lastName: "", email: "",
            roleId: "", organizationId: "", role: "", organization: "", market: "", editMode: false
        });
    };

    const handleClickDelete = () => {
        DeleteSelectedUser(selectedRow.email, selectedRow.firstName, selectedRow.lastName);
    };

    const handleClickEdit = () => {
        if (selectedRow !== null) {
            setOpen(true);
            setSliderUser({
                ...sliderUser, firstName: selectedRow.firstName,
                lastName: selectedRow.lastName, email: selectedRow.email,
                roleId: selectedRow.roleId, organizationId: selectedRow.organizationId,
                role: selectedRow.role, organization: selectedRow.organization, market: selectedRow.market, marketIds: selectedRow.marketIds,
                editMode: true
            });
        }
    };

    const DeleteSelectedUser = (email, firstName, lastName) => {
        DeleteUser({
            "email": email,
            "organizationId": userOrg.id
        })
            .then(data => {
                if (data.ok === true) {
                    initializeUsers();
                    setSelected('');
                    setEnableSelect(true);
                    setOpenDialog(true);
                    setDialogMessage(`${firstName} ${lastName} has been successfully deleted`);
                } else {
                    setOpenDialog(true);
                    setDialogMessage(data.errorMessage);
                }
            })
    };

    const saveSliderUser = () => {
        sliderUser.editMode ?
            UpdateUser({
                "firstName": sliderUser.firstName,
                "lastName": sliderUser.lastName,
                "email": sliderUser.email,
                "roleId": sliderUser.roleId,
                "organizationId": sliderUser.organizationId,
                "marketIds": sliderUser.marketIds
            })
                .then(data => {
                    if (data.ok == false) {
                        setOpenDialog(true);
                        setDialogMessage(data.errorMessage);
                    }
                    initializeUsers();
                    setSelected('');
                })
            :
            CreateUser({
                "firstName": sliderUser.firstName,
                "lastName": sliderUser.lastName,
                "email": sliderUser.email,
                "roleId": sliderUser.roleId,
                "organizationId": sliderUser.organizationId,
                "marketIds": sliderUser.marketIds
            })
                .then(data => {
                    initializeUsers();
                    setSelected('');
                });
            // CreateAdPreRegisteredUser({
            //     "firstName": sliderUser.firstName,
            //     "lastName": sliderUser.lastName,
            //     "email": sliderUser.email,
            //     "roleId": sliderUser.roleId,
            //     "organizationId": sliderUser.organizationId,
            //     "marketIds": sliderUser.marketIds
            // })
            //     .then(data => {
            //         if (data.ok === true) {
            //             initializeUsers();
            //             setSelected('');
            //             setOpenDialog(true);
            //             setDialogMessage(`${sliderUser.firstName} ${sliderUser.lastName} has been successfully created`);
            //         } else {
            //             setOpenDialog(true);
            //             setDialogMessage(data.errorMessage);
            //         }
            //     });
    };

    const handleDialogOk = () => {
        setOpenDialog(false);
    }

    const handleClose = () => {
        setOpen(false);
        setEnableSelect(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleDone = () => {
        setOpen(false);
        saveSliderUser();
        setEnableSelect(true);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setSliderUser({ ...sliderUser, [name]: value });
    };

    const handleClick = (event, name, row) => {
        let newSelected = selected;

        if (name !== selected) {
            newSelected = name;
        }
        setSelected(newSelected);
        setSelectedRow({
            firstName: row.firstName,
            lastName: row.lastName,
            email: row.email,
            role: row.role,
            roleId: row.roleId,
            organization: row.organization,
            organizationId: row.organizationId
        });
    };

    const selectedRowValues = (row) => {
        setSelectedRow({
            firstName: row.original.firstName,
            lastName: row.original.lastName,
            email: row.original.email,
            role: row.original.role,
            roleId: row.original.roleId,
            organization: row.original.organization,
            organizationId: row.original.organizationId,
            marketIds: row.original.marketIds
        });
    }

    const tableConfig = {
        isEditable,
        isResizable: true,
        isSortable: true,
        withDragAndDrop,
        withPagination: false,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search by first name...',
    };

    //................. Search.....................//

    const handleSearchInput = (event) => {
        setSearchInput(event.target.value)
        console.log(event.target.value)
        console.log(event.target.value.length)
        if (event.target.value.length === 0) {
            setSearchInput('');
        }
    };

    const handleSearch = () => {
        if (searchInput) {
            let userArray = [];

            SearchUser(searchInput.trim(), userOrg.id)
                .then(data => {
                    if (data && Array.isArray(data.users)) {
                        data.users.map(user => {
                            userArray.push(
                                {
                                    firstName: user.firstName,
                                    lastName: user.lastName,
                                    email: user.email,
                                    role: user.role.name,
                                    organization: user.organization.name,
                                    marketIds: user.marketIds,
                                    roleId: user.role.id,
                                    organizationId: user.organization.id,
                                }
                            );
                        })
                    }
                    setUsers(userArray);
                });
        }
    }

    const isSelected = (name) => selected.indexOf(name) !== -1 && selected.length === name.length;
    const anchor = "right";
    const slider = (anchor) => (
        <div
            className={clsx(classes.slider, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation">
            <Card classes={{ root: classes.cardStyle }} style={{ backgroundColor: 'rgb(19 19 19 / 90%)' }} >
                <CardHeader title={sliderUser.editMode ? "Edit Existing User" : "Add New User"} />
                <Divider />
                <CardContent>
                    <FormTextField name="firstName" labelText="First Name" value={sliderUser.firstName} onChange={handleChange}></FormTextField>
                    <FormTextField name="lastName" labelText="Last Name" value={sliderUser.lastName} onChange={handleChange}></FormTextField>
                    <FormEmailField name="email" labelText="Email" value={sliderUser.email} onChange={handleChange}></FormEmailField>
                    <Divider className={classes.divider} />
                    <RoleSelect value={sliderUser.roleId} onChange={handleChange}></RoleSelect>
                    <OrganizationSelect value={sliderUser.organizationId} onChange={handleChange}></OrganizationSelect>
                    <MarketMultiselect email={sliderUser.email} name="market" orgId={userOrg.id} onChange={handleChange} editMode={sliderUser.editMode} labelText="Market"></MarketMultiselect>
                </CardContent>
                <CardActions classes={{ root: classes.cardActionStyle }} style={{ marginBottom: '-170px' }}  >
                    <Button variant="contained" onClick={handleClose} color="default">
                        Cancel
                    </Button>
                    <DoneButton handleDone={handleDone} placeholder='Done'></DoneButton>
                </CardActions>
            </Card>
        </div>
    );

    const textFieldKeypress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const handleChangeAdministration = (value) => (event) => {
        setDisplayUsers(false);
        let selected = event.target.value;
        if (value == 'administration') {
            if (selected == 'Users') {
                setDisplayUsers(true);
                setDisplayRoles(false);
                setDisplayPermissions(false);
                setDisplayOrganizations(false);
                setDisplayFleets(false);
                setDisplayInternalAssets(false);
            }
            if (selected == 'Roles') {
                setDisplayUsers(false);
                setDisplayRoles(true);
                setDisplayPermissions(false);
                setDisplayOrganizations(false);
                setDisplayFleets(false);
                setDisplayInternalAssets(false);
            }
            if (selected == 'Permissions') {
                setDisplayUsers(false);
                setDisplayRoles(false);
                setDisplayPermissions(true);
                setDisplayOrganizations(false);
                setDisplayFleets(false);
                setDisplayInternalAssets(false);
            }
            if (selected == 'Organizations') {
                setDisplayUsers(false);
                setDisplayRoles(false);
                setDisplayPermissions(false);
                setDisplayOrganizations(true);
                setDisplayFleets(false);
                setDisplayInternalAssets(false);
            }
            if (selected == 'Fleets') {
                setDisplayUsers(false);
                setDisplayRoles(false);
                setDisplayPermissions(false);
                setDisplayOrganizations(false);
                setDisplayFleets(true);
                setDisplayInternalAssets(false);
            }
            if (selected == 'Internal Assets') {
                setDisplayUsers(false);
                setDisplayRoles(false);
                setDisplayPermissions(false);
                setDisplayOrganizations(false);
                setDisplayFleets(false);
                setDisplayInternalAssets(true);
            }
            setDisplayValue(event.target.value);
        };
    };

    const handleCloseMagnifyIcon = () => {
        setIconMagnify(imgsMagnifyIcon[0]);
        setOpenIconMagnify(false);
    };

    const handleOpenMagnifyIcon = () => {
        setIconMagnify(imgsMagnifyIcon[1]);
        setOpenIconMagnify(true);
    };

    const handleCloseSearchbar = () => {
        setShowSearchBar(!showSearchBar);
        setToggleSearchBar(!toggleSearchBar);
    };

    const handleSearchbar = () => {
        setShowSearchBar(false);
        setToggleSearchBar(false);
        initializeUsers();
        setSearchInput('');
    };

    const matchesPermission = userPermissions.includes(permission.Administration_Administrator);

    return (
        <Container>
            <VehiclePageDialog
                open={openDialog}
                dialogue='true'
                dialogHeight='true'
                message={dialogMessage}
                handleDialogOk={handleDialogOk}
            />

            <SwipeableDrawer
                classes={{
                    paper: classes.SwipeableDrawer,
                }}
                anchor={anchor}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
            >
                {slider(anchor)}
            </SwipeableDrawer>
            <Row>
                <Col md={12} lg={12}>
                    <div id="tablecard">
                        <Row className='headerStyleAdmin'>
                            <Col lg="6" md="6" sm="6" xs="6" ></Col>
                            <Col lg="2" md="2" sm="2" xs="2">

                                {
                                    displayUsers && showSearchBar ?
                                        <div className='searchStyleAdmin'>
                                            <SearchField
                                                handleSearchbar={handleSearchbar}
                                                placeholder={'User email'}
                                                handleSearch={handleSearch}
                                                handleSearchInput={handleSearchInput}
                                                onKeyPress={textFieldKeypress}
                                                value={searchInput}
                                                priceBucketActiveTab='true'
                                                autoFocus='true'
                                            />
                                        </div>
                                        :
                                        displayUsers &&
                                        <Tooltip
                                            title="open search"
                                            onOpen={handleOpenMagnifyIcon}
                                            onClose={handleCloseMagnifyIcon}
                                            style={{ zIndex: 200 }}
                                        >
                                            <IconButton
                                                onClick={handleCloseSearchbar}
                                            >
                                                <img
                                                    src={iconMagnify}
                                                    className="magnifyIconAdmin"
                                                />
                                            </IconButton>
                                        </Tooltip>
                                }
                            </Col >

                            <Col lg="2" md="2" sm="2" xs="2" className='columnstyle'>
                                {
                                    showTable && displayUsers && matchesPermission &&
                                    <div className='toolbarstyleAdmin'>
                                        <EnhancedTableToolbar
                                            numSelected={selected}
                                            handleClickOpen={handleSliderOpen}
                                            handleClickDelete={handleClickDelete}
                                            handleClickEdit={handleClickEdit}
                                            enableSelect={enableSelect}
                                        />
                                    </div>
                                }
                            </Col>

                            <Col lg="2" md="2" sm="2" xs="2" ></Col>
                        </Row>
                        <>
                            <Fade bottom duration={1500}>
                                <div className="tableFixHeadAdmin">
                                    {
                                        showTable && displayUsers &&
                                        <ReactTableBase
                                            key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                                            columns={columns}
                                            data={rows}
                                            tableConfig={tableConfig}
                                            onClick={(event) => handleClick(event, rows.email, rows)}
                                            onRowSelected={handleClick}
                                            isSelected={isSelected}
                                            selectedRows={selectedRows}
                                            onSelectedRowsChange={setSelectedRows}
                                            selectedRowValues={selectedRowValues}
                                            setEnableSelect={setEnableSelect}
                                            enableSelect={enableSelect}
                                        />
                                    }
                                </div>
                            </Fade>
                        </>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state),
    userPermissions: selectUserPermissions(state),
    userToken: selectUserToken(state)
});

export default connect(mapStateToProps, null)(UserTable);